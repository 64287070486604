@import "../../scss/variables";
.filter-container-hierarchy {
  .react-dropdown-tree-select .dropdown {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .dropdown-trigger {
      display: none;
    }
    .dropdown-content {
      position: static;
      width: 100%;
      padding: 0px;
      background-color: transparent;
      border: none;
      ul {
        width: 100%;
        height: auto;
        display: flex;
        > div {
          width: 100%;
        }
        .infinite-scroll-component {
          width: 100%;
          display: flex;
          // flex-direction: column;
          flex-wrap: wrap;
          .node {
            max-width: 100%;
            display: flex;
            overflow-wrap: break-word;
            padding: 0px;
            padding-left: 0px !important;
            font-size: 1.3rem;
            flex-wrap: nowrap;
            min-width: 100%;
            &.focused {
              background-color: transparent;
            }
            .toggle {
              display: block;
              position: relative;
              margin: 0px;
              margin-top: 2px;
              // margin-left: $spacer;
              //   border: 1px solid #000;
              width: $spacer * 2;
              &:before {
                content: "";
                position: absolute;
                left: 0px;
                top: 0px;
                width: 100%;
                height: 100%;
                border-radius: 50%;
                border-radius: 50%;
                background-color: $red;
                width: $spacer;
                height: $spacer;
              }
              &:after {
                position: absolute;
                left: 0px;
                top: 0px;
                width: $spacer;
                font-style: normal;
                font-weight: bold;
                text-align: center;
                margin: 0px;
                color: #fff;
                font-size: 15px;
                line-height: 15px;
                display: block;
                font-family: "Ultra", serif;
                font-weight: 400;
              }
            }
            &.tree {
              width: 100%;
              min-width: 100%;
              border: none;
              margin-bottom: calc($spacer / 2);
            }
            &.leaf {
              // max-width: 50%;
              // min-width: 50%;
              margin-bottom: 6px;
              label {
                margin-left: 2px;
              }
              i {
                height: 10px;
              }
            }
            label {
              width: 100%;
              display: flex;
              overflow-wrap: break-word;
              align-items: flex-start;
              .checkbox-item {
                margin-top: 4px;
              }
            }
            &-label {
              width: 100%;
              // display: inline-block;
              white-space: normal;
              overflow-wrap: break-word;
              line-height: 20px;
            }
          }
        }
      }
    }
  }
}
