
.search-container {
    .filterdropdown-search {
        &-container {
            // border: 4px solid #C00;
        }
        &__indicators {
            display: none;
        }
    }
}