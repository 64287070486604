@import "../../scss/variables";
.reset-container {
  border: 1px solid #0C0;
  position: absolute;
  bottom: 100%;
  right: 0px;
  .remove-searches {
    font-size: 1.8rem;
    pointer-events: all;
    &:hover {
      cursor: pointer;
    }
  }
}
.tools-list {
  margin: 0px;
  padding: 0px;
  padding-right: $spacer;
  padding-top: $spacer;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  pointer-events: none;
  li {
    pointer-events: all;
    margin-left: auto;
    margin-bottom: $spacer * 0.5;
    display: flex;
    flex-direction: row;
    label {
      position: relative;
      display: none;
      background-color: #fff;
      line-height: $canvasButtonHeight;
      padding: 0px $spacer;
      font-size: 1.4rem;
      color: $red;
      border-bottom-left-radius: 4px;
      border-top-left-radius: 4px;
      box-shadow: inset 0px 0px 1px 1px $darkBorder;
      margin-right: -1px;
      &:hover {
        cursor: pointer;
      }
      // box-shadow: inset 0px -1px 0px $darkBorder;
      svg {
        margin-right: 6px;
        margin-top: -2px;
        // fill: #FFF;
      }
      &.label-dark {
        color: $dark;
      }
      &.min-150 {
        min-width: 150px;
      }
      .label-tool-container {
        max-width: 150px;
        // position: absolute;
        // bottom: 100%;
        // right: 0px;
        // width: 200px;
        line-height: 1.2rem;
        font-size: 1.1rem;
        // background-color: #fff;
        color: $dark;
        // padding: $spacer;
        padding-bottom: $spacer;
        ul {
          padding: 0px;
          li {
            padding-left: $spacer * 1.5;
            position: relative;
            &:before {
              position: absolute;
              left: 0px;
              content: "";
              height: $spacer;
              width: $spacer;
              display: inline-block;
              // border: 1px solid #C00;
              background-size: cover;
            }
            &.icon-node {
              &:before {
                background-image: url("../../../public/images/icon-node.svg");
              }
            }
            &.icon-link {
              &:before {
                background-image: url("../../../public/images/icon-link.svg");
              } 
            }
            &.icon-cluster {
              &:before {
                background-image: url("../../../public/images/icon-cluster.svg");
              } 
            }
          }
          &.legend-network {
            // background-color: #0C0;
          }
        }
      }
    }
    button.btn {
      &-toolbar {
        display: inline-block;
        font-size: 1.8rem;
        border-radius: 4px;
        line-height: 1;
        background-color: $darkBorder;
        border-color: $darkBorder;
        color: #FFF;
        height: $canvasButtonHeight;
        width: $canvasButtonHeight;
        position: relative;
        padding: 0px;
        polygon {
          stroke: $darkBorder;
        }
        &:hover {
          border-color: darken($darkBorder, 10%);
          background-color: darken($darkBorder, 10%);
          border-bottom-left-radius: 0px;
          border-top-left-radius: 0px;
          // border-left-color: #FFF;
          cursor: pointer;
        }
        &:active,
        &:focus {
          color: #FFF;
          // background-color: $dark;
          // box-shadow: none;
        }
        &-filter {
          font-size: 2.2rem;
          svg {
            stroke-width: 0.5px;
          }
        }
        &-highlighted {
          color: #FFF;
          border-color: $toolbarGray;
          background-color: $toolbarGray;
          &:hover {
            background-color: darken($toolbarGray, 10%);
            border-color: darken($toolbarGray, 10%);
          }
          // &:active,
          // &:focus {
          //   color: #FFF;
          //   background-color: $red;
          //   box-shadow: none;
          // }
        }
      }
    }

    &:hover,
    &:active,
    &:focus {
      label {
        display: inline-block;
      }
    }
    &.active-tool {
      label {
        display: none;
      }
      button.btn {
        &-toolbar {
          color: #FFF;
          background-color: $dark;
          border-color: $dark;
          // border-bottom-left-radius: 0px;
          // border-top-left-radius: 0px;
          &-highlighted {
            color: #FFF;
            background-color: $red;
            border-color: $red;
            &:hover {
              // color: #FFF;
              // background-color: $red;
              // border-color: $red;
            }
          }
        }
        &:hover {
          border-bottom-left-radius: 4px;
          border-top-left-radius: 4px;
        }
      }
    }
  }
}
