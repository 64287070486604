@import '../../scss/variables';
.ambits-chart-container {
    // width: calc($leftPanelWidth - ($canvasButtonHeight + $spacer*2));
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 0px 6px 1px $gray;
    z-index: 99;
    .bars-container {
        .bar {
            .ambit-label {
                font-size: 1.2rem;
                line-height: 1.2;
                margin: 0px;
                display: flex;
                font-weight: normal;
                small {
                    margin-left: auto;
                }
                margin-bottom: $spacer * 0.25;
                margin-top: $spacer * 0.75;
            }
            .rail {
                position: relative;
                width: 100%;
                background-color: $gray;
                height: $spacer * 0.5;
                .track {
                    position: absolute;
                    left: 0px;
                    top: 0px;
                    height: 100%;
                    background-color: $red;
                    width: 50%;
                }
            }
            &:first-child {
                .ambit-label {
                    margin-top: 0px;
                }
            }
        }
    }
}