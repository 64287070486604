@import 'scss/variables';

html {
  font-size: 62.5%;
}

body, html {
  height: 100%;
}

body {
  margin: 0;
  overflow: hidden;
  .root {
    height: 100vh;
    width: 100vw;
    .app-container {
      height: 100vh;
      width: 100vw;
    }
  }
}

// TODO
// Responsive states
// 2 scenarios 
// smaller than -> show the floating panels, panel relative size

@import 'scss/type';
@import 'scss/layout';
@import 'scss/tabs';
@import 'scss/buttons';
@import 'scss/informative_panels';
@import 'scss/indicators';
@import 'scss/loaders';
@import 'scss/responsive';