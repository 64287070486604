@import "../../scss/variables";

.projects-list--header {
  font-weight: bold;
}
.MuiDataGrid-columnHeader {
  background-color: $light;
}

.MuiDataGrid-root {
  border-radius: 0px !important;
}

.MuiDataGrid-columnHeaderDraggableContainer {
  border-radius: 0px;
}

.MuiDataGrid-columnHeaders {
  border-radius: 0px;
}
.MuiDataGrid-columnHeaderTitle {
  font-size: 1.2rem !important;
  font-weight: bold !important;
}
.MuiDataGrid-row {
  // padding-top: 6px;
  // padding-bottom: 6px;
  &:hover {
    cursor: pointer;
  }
  .MuiDataGrid-cellContent {
    font-size: 1.2rem !important;
    p {
      font-size: 1.2rem !important;
    }
  }
}

.MuiDataGrid-cell {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  &:focus {
    outline: none !important;
  }
}

.MuiDataGrid-cell--withRenderer {
  font-size: 1.2rem !important;
  p {
    font-size: 1.2rem !important;
  }
}

.MuiDataGrid-footerContainer {
  font-size: 1.2rem !important;
  p {
    font-size: 1.2rem !important;
    margin: 0px;
  }
  .MuiTablePagination-root {
    font-size: 1.2rem !important;
    .MuiTablePagination-selectLabel,
    .MuiTablePagination-displayedRows {
      font-family: "Open Sans", sans-serif !important;
    }
  }
  .MuiDataGrid-selectedRowCount {
    opacity: 0;
  }
}
