@import "../../scss/variables";
.legend-options {
  margin: 0px;
  padding: 0px;
  padding-top: 0;
  padding-left: $spacer*2.5;
  li {
    span {
      font-size: 1.2rem;
      color: $dark;
    }
  }
}
