@import "../../scss/variables";
.searches-panel {
    .search-container {
        // background-color: $light;
        position: relative;
        z-index: 100;
        padding: 20px;
        h4 {
            font-size: 1.4rem;
            margin-bottom: $spacer;
        }
        &-entitats {
            z-index: 1;
        }
        &-keywords {
            z-index: 10;
        }
        .search-component-container {
            display: flex;
            .filterdropdown-search-container {
                flex: 1;
                max-width: 100%;
                // max-width: calc(100% - 31px); // remove button width
            }
            .btn {
                border-top-left-radius: 0px;
                border-bottom-left-radius: 0px;
            }
        }

    }
}
