@import "../../scss/variables";

.sparkline {
  width: 180px;
  // display: flex;
}
.rail {
  position: relative;
  width: 100%;
  background-color: $gray;
  height: $spacer * 1.5;
  .track,
  .subtrack {
    position: absolute;
    left: 0px;
    background-color: #FFF;
    width: 50%;
  }
  
  .track {
    top: 0px;
    height: 100%;
  }

  .subtrack {
    top: 35%;
    height: 30%;
    // margin-top: -25%;
  }
}
.label {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 1.3rem;
  font-weight: bold;
  white-space: nowrap;
}

.bar-label {
  display: inline-block;
  font-size: 1.3rem;
  font-weight: bold;
  text-align: right;
  width: 100%;
  line-height: $spacer * 1.5;
}

.x-axis,
.y-axis {
  stroke: transparent;
  text {
    fill: none;
  }
  .tick {
    line {
      stroke: transparent;
    }
  }
  path {
    stroke: transparent;
  }
}

.sparkline-container {
  svg {
    // overflow: visible;
    .sparkline-bar {
      &:hover {
        fill: $dark;
      }
    }
  }
}