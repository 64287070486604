@import "../../scss/variables";

.main-header {
  background: $dark;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 50;
  .row-logos,
  .row-title {
    height: calc($headerHeight / 2);
  }
  .row-logos {
    padding: 0px $spacer;
    .gencat-logo {
      padding-top: calc($spacer / 2);
    }
    .europa-logo {
      padding-top: 6px;
    }
  }
  .row-title {
    background-color: $headerGray;
    margin-top: auto;
    .title {
      font-size: 2rem;
      color: #fff;
      margin: 0px;
      padding-left: $spacer;
      line-height: calc($headerHeight / 2);
    }
    .links-nav {
      display: flex;
      justify-content: center;
      // margin-top: 12px;
      ul {
        list-style-type: none;
        padding: 0px;
        margin: 0px;
        padding-top: calc($spacer / 2);
        padding-bottom: calc($spacer / 2);
      }
      li {
        display: inline-block;
        border-left: 1px solid #fff;
        padding: 0px 24px;
        &:first-child {
          border: none;
        }
        a {
          display: inline-block;
          font-size: 1.4rem;
          font-weight: 600;
          color: #fff;
          text-decoration: none;
          line-height: 24px;
          svg {
            margin-top: -4px;
          }
          &:hover {
            text-decoration: underline;
          }
        }
        // &:last-child {
        //   a {
        //     font-size: 1.6rem;
        //   }
        // }
        &:hover {
          .source-container {
            display: block;
          }
        }
        .source-trigger {
          color: #fff;
          font-size: 1.4rem;
          display: inline-block;
          line-height: 24px;
          &:hover {
            cursor: pointer;
          }
        }
        .source-container {
          display: none;
          position: absolute;
          top: calc($headerHeight - ($spacer));
          right: 0px;
          color: $dark;
          width: $leftPanelWidth;
          font-size: 1.2rem;
          padding-top: $spacer;
          // border: 1px solid $red;
          .source-content {
            padding: $spacer;
            background-color: #fff;
            border: 1px solid $darkBorder;
            border-top: none;
            .intro-text {
              font-size: 1.4rem;
            }
            a {
              color: $red;
              font-size: 1.2rem;
            }
            ul {
              padding: 0px;
              li {
                line-height: 1.6rem;
                padding: 0px;
                margin-bottom: 6px;
                a {
                  font-size: 1.2rem;
                  color: $red;
                }
              }
            }
          }
        }
      }
    }
  }
}

.lang-trigger {
  position: relative;
  line-height: 1.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  > span {
    font-weight: bold;
  }
  &:hover {
    cursor: pointer;
    > span {
      text-decoration: underline;
    }
  }
  .lang-container {
    position: absolute;
    top: 100%;
    right: -28px;
    color: #3a3a3a;
    background-color: #fff;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3);
    min-width: 144px;
    z-index: 9;

    .lang-content {
      ul {
        li {
          display: block;
          padding: 6px 16px;
          button.lang-btn {
            // color: #c00;
            // Remove default button styles
            background: none;
            border: none;
            padding: 0;
            font: inherit;
            cursor: pointer;
            outline: inherit;
            &:hover {
              text-decoration: underline;
            }
            // Styles form GENCAT
            font-size: 12px;
            margin-bottom: 0;
            font-weight: bold;
          }
        }
      }
    }
  }
}
