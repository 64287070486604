.informative-panel {
    position: absolute;
    z-index: 99;
    // right: $canvasButtonHeight + $spacer*2;
    top: $spacer;
    width: calc($leftPanelWidth - ($canvasButtonHeight + $spacer*2));
    max-width: 350px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    max-height: calc($canvasHeight - $spacer * 2);
    box-shadow: 0px 0px 6px 1px $gray;
    // border: 1px solid $darkBorder;
    .btn-close-window {
        position: absolute;
        right: $spacer;
        top: calc($spacer / 2);
        font-size: 2rem;
        padding: 0px;
        color: $red;
        border: none;
        &:active {
            color: darken($red, 5%);
            border: none;
        }
    }
    h3 {
        margin: 0px;
        padding-right: $spacer * 3;
    }
    h4 {
        // margin: 12px 0px;
    }
    h3, h4, h6 {
        font-weight: 500;
    }
    &-body {
        overflow: hidden;
        // overflow-y: auto;
        padding: $spacer;
        display: flex;
        flex-direction: column;
        height: 100%;
    }
    &-footer {
        margin-top: auto;
        padding: $spacer;
        padding-top: 0px;
        // background-color: $light;
        display: flex;
        // border-bottom-left-radius: $spacer * 0.5;
        // border-bottom-right-radius: $spacer * 0.5;
    }
    &-participants {
        list-style-type: none;
        padding: 0px;
        margin: 0px;
        li {
            display: flex;
            border-top: 1px solid $gray;
            line-height: 28px;
            font-size: 1.3rem;
            small {
                margin-left: auto;
            }
        }
    }

}
.panel-internal-scrolling-container {
    flex: 1;
    // box-shadow: inset 0px 0px 0px 1px #00C;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 100%;
    .col {
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow: hidden;
        h6 {
            // box-shadow: inset 0px 0px 0px 1px #C0C;
        }
        .panel-internal-scrolling {
            flex: 1;
            // box-shadow: inset 0px 0px 0px 1px #0CC;
            // max-height: 20vh;
            overflow: hidden;
            overflow-y: auto;
            height: 100%;
            max-height: 100%;
            ul {
                padding: 0px;
                list-style-type: none;
                margin: 0px;
                li {
                    padding-top: 6px;
                    padding-bottom: 6px;
                    border-top: 1px solid $gray;
                    font-size: 1.3rem;
                }
            }
        }
    }
}

.scrollable-accordion {
    overflow: hidden;
    overflow-y: auto;
    &.single {
        max-height: calc(100vh - 52px);
    }
    &.double {
        max-height: calc(100vh - 104px);
    }
}

.custom-accordion {
    width: 100%;
    margin-top: auto;
    max-height: calc($canvasHeight - ($spacer*2));
}
.custom-accordion>* {
    border: 0;
}

.accordion-button:not(.collapsed) {
    background-color: rgba(0, 0, 0, 0.15);
    color: black;
}

.accordion-button.collapsed {
    background-color: rgba(0, 0, 0, 0.1);
    color: black;
}

.vg-tooltip .key {
    display: none;
}

.vg-tooltip h2 {
    font-weight: bold;
    font-size: 1.8em !important;
    margin-bottom: 0px !important;
}

.vg-tooltip .value {
    font-size: 1.2em !important;
}


.panel-title {
    text-transform: uppercase;
    font-size: 11px;
    border-top: 1px solid #CCC;
    border-bottom: 1px solid #CCC;
    padding: 6px 20px;
    margin-left: -20px;
    margin-right: -20px;
    margin-top: 12px;
}

// PANEL RIGHT SIDE

.right-side-panel {
    > header {
        display: flex;
        padding: 20px;
        align-items: center;
        h6 {
            font-size: 1.4rem;
            margin: 0px;
          }
    }
    .form-check-label {
        font-size: 1.3rem;
    }
    .search-container {
        // background-color: $light;
        position: relative;
        z-index: 100;
        padding: 20px;
        h4 {
            font-size: 1.4rem;
            margin-bottom: $spacer;
        }
        &-entitats {
            z-index: 1;
        }
        &-keywords {
            z-index: 10;
        }
        .search-component-container {
            display: flex;
            .filterdropdown-search-container {
                flex: 1;
                // max-width: calc(100% - 31px); // remove button width
            }
            .btn {
                border-top-left-radius: 0px;
                border-bottom-left-radius: 0px;
            }
        }

    }
}

.searches-panel {
    > header {
        display: flex;
        padding: 20px;
        align-items: center;
        h6 {
            font-size: 1.4rem;
            margin: 0px;
          }
    }
    .search-container {
        // background-color: $light;
        position: relative;
        z-index: 100;
        padding: 20px;
        h4 {
            font-size: 1.4rem;
            margin-bottom: $spacer;
        }
        &-entitats {
            z-index: 1;
        }
        &-keywords {
            z-index: 10;
        }
        .search-component-container {
            display: flex;
            .filterdropdown-search-container {
                flex: 1;
                // max-width: calc(100% - 31px); // remove button width
            }
            .btn {
                border-top-left-radius: 0px;
                border-bottom-left-radius: 0px;
            }
        }

    }
}
