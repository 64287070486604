@import "../scss/variables";

.canvas-container {
  .main-tabs {
    width: 100%;
    height: 100%;
    height: $mainTabsHeight;
    border: none;
    background-color: #fff;
    box-shadow: inset 0px -1px 0px 0px $gray;
    .nav-item {
      position: relative;
      .nav-link {
        border: none;
        border-radius: 0px;
        height: $mainTabsHeight;
        font-size: 1.6rem;
        font-weight: 600;
        padding: 0px $spacer * 1.5;
        position: relative;
        color: $dark;
        &:hover,
        &.active {
          color: $dark;
          background-color: transparent;
          &:after {
            content: "";
            position: absolute;
            bottom: 0px;
            left: 50%;
            height: 3px;
            width: $spacer * 6;
            margin-left: -$spacer * 3;
            background-color: $red;
          }
        }
      }
      &:before {
        content: "";
        position: absolute;
        right: 0px;
        top: 50%;
        width: 2px;
        height: $spacer * 1.5;
        margin-top: -$spacer * 0.75;
        background-color: $gray;
      }
      &:last-child {
        &:before {
          display: none;
        }
      }
    }
  }
  .tab-content {
    height: $canvasHeight;
    background-color: $light;
    .tab-pane {
      height: $canvasHeight;
      position: relative;
      z-index: 1;
      overflow: hidden;
    }
  }
}

.statistic-module-tabs {
  box-shadow: inset 0px 1px 0px 0px $gray;
  border: none;
  .nav {
    .nav-item {
      box-shadow: inset -1px 0px 0px 0px $gray;
      &:last-child {
        box-shadow: none;
      }
      .nav-link {
        padding: 0px $spacer * 2;
        line-height: $secondaryTabsHeight;
        margin: 0px;
        border: none;
        color: $dark;
        font-weight: bold;
        svg {
          fill: $red;
          margin-right: $spacer*0.5;
        }
        .close {
          display: inline-block;
        }
        .open {
          display: none;
        }
        &:hover {
            text-decoration: underline;
        }
        &.active {
          background-color: $light;
          pointer-events: none;
          .close {
            display: none;
          }
          .open {
            display: inline-block;
          }
        }
      }
    }
  }
  .statistic-module-panel {
    background-color: $light;
    height: $statisticModulePanelHeight;
  }
}
