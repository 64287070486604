@import "../../scss/variables";
.bars-container {
  text {
    &.bar-label {
      fill: white;
      text-anchor: end;
    }
    &.bar-label-outer {
      text-anchor: start;
      fill: $dark;
    }
    font-size: 1.2rem;
    font-weight: bold;
  }
  .tick {
    text {
      font-weight: normal;
    }
    line {
      opacity: 0;
    }
  }
  .axis {
    .domain {
      opacity: 0;
    }
  }

  .bar {
    &:hover {
      fill: $dark;
      cursor: pointer;
    }
  }
}
