/* Import the font using @font-face */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans Regular'), local('OpenSans-Regular'), url('../fonts/OpenSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: local('Open Sans Semibold'), local('OpenSans-Semibold'), url('../fonts/OpenSans-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Ultra';
  font-style: normal;
  font-weight: normal;
  src: local('Ultra Regular'), local('Ultra-Regular'), url('../fonts/Ultra-Regular.ttf') format('truetype');
}

body {
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: 1.4rem;
  color: $dark;
  strong,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 600;
  }
}

code {
  font-family: "Open Sans", sans-serif;
}

svg {
  text {
    font-family: "Open Sans", sans-serif;
  } 
}
