@import "../../scss/variables";
$modalWidth: 90vw;
$modalHeight: 94vh;
.project-info-modal {
  &.lightbox {
    background-color: rgba(0, 0, 0, 0.75);
  }
  position: fixed;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  z-index: 99;
  .project-modal {
    background-color: #fff;
    max-width: $modalWidth;
    width: $modalWidth;
    max-height: $modalHeight;
    height: $modalHeight;
    padding: 0px;
    display: flex;
    flex-direction: column;
    &-header {
      position: relative;
      background-color: $red;
      color: #fff;
      padding: $spacer;
      .btn {
        position: absolute;
        right: $spacer;
        top: $spacer;
        color: #fff;
        padding: 0px;
        border: none;
        svg {
          width: $spacer * 1.5;
          height: $spacer * 1.5;
        }
        &:hover {
          color: #fff;
        }
        &:active {
          border: none;
        }
      }
    }
    &-abstract {
      background-color: lighten($red, 60%);
      padding: $spacer;
      font-size: 1.2rem;
      overflow: hidden;
      overflow-y: auto;
      height: 100%;
      p {
        margin: 0px;
      }
    }
    &-body {
      height: 100%;
      > .row {
        height: 100%;
        // box-shadow: inset 0px 0px 0px 4px #C0C;
      }
      overflow: hidden;
      // overflow-y: auto;
      // padding: $spacer;
      display: flex;
      flex-direction: column;
      flex: 1;
    }
    &-footer {
      margin-top: auto;
      padding: $spacer;
      background-color: $light;
      display: flex;
      // border-bottom-left-radius: $spacer * 0.5;
      // border-bottom-right-radius: $spacer * 0.5;
      button {
        font-size: 1.4rem;
        color: $dark;
        font-weight: bold;
        &:hover {
          color: #000;
        }
      }
    }
  }
}

.project-modal-indicators-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: $spacer;
}
.modal-internal-indicators-container {
  // flex: 1;
}
.modal-internal-scrolling-container {
  flex: 1;
  overflow: hidden;
  display: flex;
  align-items: stretch;
  margin-top: 12px;
  // height: 100%;
  > div {
    overflow: hidden;
    max-height: 100%;
  }
  .modal-internal-scrolling {
    padding-top: 9px;
    overflow: hidden;
    width: 100%;
    // max-height: 20vh;
    height: 100%;
    overflow-y: auto;
    ul {
      margin: 0px;
      padding: 0px;
      list-style-type: none;
      li {
        display: flex;
        font-size: 1.3rem;
        padding-top: 3px;
        padding-bottom: 3px;
        border-bottom: 1px solid $gray;
        &:last-child {
          border: none;
        }
        small {
          display: block;
          margin-left: auto;
          white-space: nowrap;
          padding-left: $spacer;
        }
      }
    }
  }
}
