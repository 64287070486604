.loader-container {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
    height: 100%;
    // background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader {
    font-size: 1.4rem;
}
.loading-spinner {
    width: $spacer * 2.5;
    height: $spacer * 2.5;
  }