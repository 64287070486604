@import '../../scss/variables';

.visibility-container {
    // left: $leftPanelWidth;
    // padding: $spacer 0px;
    position: absolute;
    top: calc(100% - 2px);
    right: 0px;
    pointer-events: all;
    border: 1px solid $darkBorder;
    border-top: none;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    // border-right: none;
    display: block;
    width: 100%;
    min-width: 150px;
    padding-bottom: $spacer;
    .btn {
        display: block;
        font-size: 1.2rem;
        text-decoration: none;
        font-weight: normal;
        color: $dark;
        // color: $red;
        &:hover {
            cursor: pointer;
        }
        &-link {
            // color: $dark;
            .form-check-input {
                margin-top: calc($spacer / 2);
            }

            small, span {
                display: inline-block;
            }

            small {
                padding-top: 2px;
                width: 14px;
                height: 14px;
                border: 1px solid darken($light, 10%);
                border-radius: 50%;
                margin-right: 2px;
                &.active {
                    border: none;
                    box-shadow: inset 0px 0px 0px 4px $selectedBlue;
                }
            }

        }
    }
}