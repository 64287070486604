// @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap');

// Colors
$dark: #2D2D2D;
$grayDark: #828282;
$light: #F4F4F4;
$red: #C00000;
$gray: #DAE0E7;
$darkBorder: #9A9A9A;
$headerGray: #404040;

$toolbarGray: #666666;
// $toolbarGray: #989898;

$spacer: 15px;

$selectedBlue: #0d6efd;

// Layout
$headerHeight: 80px;
$mainTabsHeight: 50px;
$canvasButtonHeight: 40px;
$indicatorsBarHeight: 60px;
$secondaryTabsHeight: 50px;
$canvasHeight: calc(100vh - $headerHeight - $mainTabsHeight - $indicatorsBarHeight - $secondaryTabsHeight); 
$leftPanelWidth: 380px;
$statisticModulePanelHeight: 370px;
$statisticModuleChartHeight: 300px;