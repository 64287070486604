@import "../../scss/variables";
.active-filters-bar {
  position: absolute;
  right: 0px;
  top: 0px;
  // background-color: #0CC;
  z-index: 9;
  display: flex;
  height: $mainTabsHeight;
  line-height: $mainTabsHeight;
  padding: 0px $spacer;

  &-list-container {
    position: absolute;
    top: 100%;
    left: 0px;
    // background-color: #3B2;
    line-height: initial;
    padding: $spacer;
    background-color: #fff;
    border-top: 1px solid $darkBorder;
    box-shadow: 0px 0px 6px 1px $gray;
    min-width: 60%;
    // width: 100%;
    .active-filter {
        font-size: 1.2rem;
        .filter-label {
            // padding-top: $spacer;
            font-weight: bold;
        }
      ul {
        margin: 0px;
        padding: 0px;
        list-style: none;
        li {
          display: inline-block;
          margin-right: calc($spacer / 2);
          margin-bottom: calc($spacer / 2);
          padding: 0px calc($spacer / 2);
          // background-color: #0C0;
          border-radius: 4px;
          box-shadow: 0px 0px 0px 1px $darkBorder;
          font-size: 1.1rem;
          line-height: $spacer * 1.25;
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
}
