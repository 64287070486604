@import "../../scss/variables";
.bars-breadcrumb-link {
  color: $red;
  font-size: 1.5rem !important;
  &:hover {
    cursor: pointer;
    color: darken($red, 10%)
  }
}

.statistics-group {
  span {
    &:first-child {
      .btn {
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
      }
    }
    &:last-child {
      .btn {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
      }
    }
  }
}