@import '../../scss/variables';

.inactive-view {
    g {
        opacity: 0.2;
        pointer-events: none;
        * {
            pointer-events: none !important;
        }
    }
}
.svg-labels, .svg-clusters, .svg-nodes {
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 1;
}

.svg-labels, .svg-clusters, .clusters-container {
    pointer-events: none;
}

.svg-nodes {
    overflow: visible;
    z-index: 25;
    circle.projectNode {
        pointer-events: fill;
        &:hover {
            cursor: pointer;
        }
    }
    rect.label {
        pointer-events: fill;
        &:hover {
            cursor: pointer;
        }
    }
}

.siris-tooltip {
    position: absolute;
    z-index: 9;
    // right: $canvasButtonHeight + $spacer*2;
    // bottom: $spacer;
    // width: $leftPanelWidth - ($canvasButtonHeight + $spacer*2);
    max-width: 360px;
    background-color: #FFF;
    padding: $spacer*0.5 $spacer*0.8;
    border-radius: 4px;
    box-shadow: inset 0px 0px 0px 1px $gray;
    pointer-events: none;
    p {
        font-size: 1.1rem;
    }
}

p.label {
    position: absolute;
    display: inline-block;
    margin: 0px;
    // margin-top: -$spacer*3;
    z-index: 20;
    font-size: 1.2rem;
    span {
        padding: 0px $spacer*0.5;
        background-color: #fff;
        display: block;
        margin-bottom: 1px;
    }
    &:hover {
        cursor: pointer;
    }
}

.html-labels-container {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 50;
    pointer-events: none;
}
.html-label {
    position: absolute;
    max-width: 80px;
    pointer-events: fill;
    display: block;
    background-color: rgba(255,255,255,1);
    margin: 0px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 1px 3px;
    // padding-top: 1px;
    border-width: thin;
    border-style: solid;
    border-radius: 4px;
    &:hover {
        max-width: initial;
        font-weight: bold;
        cursor: pointer;
    }
}

g.label {
    font-weight: initial;
    font-size: initial;
    transform-origin: "center";
    &:hover {
        // font-weight: bold;
        cursor: pointer;    
    }
    font-size: 13px;
}

.label {
    .label-text {
        &.inverted {
            fill: #FFF !important;
        }
    }
}