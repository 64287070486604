@import "../../scss/variables";
.statistics-module {
  width: 100vw;
  position: absolute;
  bottom: 0px;
  z-index: 90;
  background-color: #fff;
  padding: 0px;
}

.statistic-module-trigger {
  position: absolute;
  width: $spacer * 2;
  height: $spacer * 2;
  text-align: center;
  left: 50%;
  margin-left: -($spacer);
  margin-top: -($spacer );
  font-size: 3rem;
  color: $red;
  background-color: #fff;
  border-radius: 50%;
  &:hover {
    cursor: pointer;
  }
  svg {
    position: absolute;
    top: 0px;
    left: 0px;
  }
}
