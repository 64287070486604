@import "../../scss/variables";
.filters-panel {
    display: flex;
    max-width: $leftPanelWidth;
    min-width: $leftPanelWidth;
    flex-direction: column;   
    overflow: hidden; 
    height: $canvasHeight;
    &-scrolling {
        flex: 1;
        overflow-y: auto;
    }
    .accordion, .search-container, .filter-item, .visibility-container {
        pointer-events: all;
    }
    .accordion {
        position: relative;
        z-index: 10;
        flex: 1;
        box-shadow: inset 0px 1px 0px 0px $gray;
        &-item {
            background-color: transparent;
            border-radius: 0px;
            border: none;
            box-shadow: inset 0px -1px 0px 0px $gray;
            // margin-bottom: 1px;
        }
        &-header {
            // background-color: $light;
                .accordion-button {
                    // padding: $spacer $spacer*2;
                    padding: $spacer 0px;
                    padding-right: $spacer;
                    border-radius: 0px;
                    background-color: transparent;
                    .btn-label {
                        font-size: 1.4rem;
                        font-weight: 600;
                        width: 80%;
                        line-height: 20px;
                    }
                    .badge {
                        font-size: 1.2rem;
                        font-weight: 600;
                        line-height: 12px;
                        margin: 0px;
                    } 
                    &:focus {
                        box-shadow: none;
                    }
                    &:after {
                        display: none;
                    }
                    .open {
                        display: inline-block;
                    }
                    .close {
                        display: none;
                    }
                    svg {
                        width: 12%;
                        height: 14px;
                        fill: $red;
                        margin: 0px;
                        transform: rotate(90);
                    }
                    &.collapsed {
                        .close {
                            display: inline-block;
                        }
                        .open {
                            display: none;
                        }
                        // svg {
                        //     transform: rotate(0);
                        // }
                    }
                }
        }
        &-collapse {
            background-color: #fff;
            box-shadow: inset 0px 1px 0px 0px $gray, inset 0px -1px 0px 0px $gray;
        }
    }
    .filters-list {
        flex: 1;
        overflow-y: auto;
        .filter-item {
            // padding: $spacer $spacer * 2;
            // background-color: #fff;
            // border: 1px solid #C00;
            h6 {
                // text-transform: uppercase;
                color: #fff;
                font-weight: bold;
            }
            padding-bottom: $spacer * 2;
            // max-width: 240px;
        }
    }
    footer {
        text-align: right;
        padding: $spacer;
    }
}
