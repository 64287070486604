@import "../../scss/variables";
.loader {
  // background-color: $light;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $dark;
  font-size: 1.2rem;
  &.size-full {
    height: 100vh;
    width: 100%;
    position: absolute;
    font-size: 1.4rem;
  }
}


