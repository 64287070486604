@import "../../scss/variables";
.tools-panels {
    pointer-events: all;
    background-color: #FFF;
    border-left: 1px solid $gray;
    overflow: hidden;
    .panel {
      display: flex;
      max-width: $leftPanelWidth;
      min-width: $leftPanelWidth;
      flex-direction: column;
      // padding-top: $spacer;
      > header {
        display: flex;
        padding: $spacer;
        align-items: center;
        .btn-close {
            margin-right: $spacer;
        }
        h6 {
          font-size: 1.4rem;
          margin: 0px;
          // line-height: 40px;
        }
      }
    }
}