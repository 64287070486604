@import "../../scss/variables";
.home {
  &-layout {
    height: 100vh;
    display: flex;
    flex-direction: column;
    .main-header {
      height: $headerHeight;
    }
    .canvas-container {
      position: relative;
      height: calc(
        100vh - $headerHeight - $indicatorsBarHeight - $secondaryTabsHeight
      );
      background-color: $light;

      .canvas-tools-container {
        pointer-events: none;
        position: absolute;
        z-index: 5;
        right: 0px;
        top: $mainTabsHeight;
        bottom: 0px;
        display: flex;
      }
      canvas {
        position: relative;
        z-index: 10;
      }
    }
  }
}

// Cookies banner
.cookies-container {
  margin: 30px;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 9999;
}
.cookieConsentWrapper {
  z-index: 99990;
  position: relative;
  left: 0;
  right: 0;
  top: 0;
  background: #f5f5f5;
  color: #333333;
  padding: 24px 30px;
  transition: 200ms;
}
.cookieConsent__Content {
  margin-right: 40px;
}
.cookieConsent__Title {
  margin: 0;
  font-weight: 600;
  font-size: 16px;
}
.cookieConsent__Description {
  margin: 10px 0 0;
  font-size: 14px;
}
.cookieConsent__MoreInfo {
  margin-top: 8px;
  font-size: 14px;
}
.cookieConsent__Button {
  display: block;
  align-self: flex-end;
  border: 0;
  border-radius: 4px;
  margin: 0px 0px 0px 12px;
  padding: 10px 24px;
  background: #c00000;
  color: #ffffff;
  font-size: 16px;
  cursor: pointer;
  transition: 200ms;
  white-space: nowrap;
  font-weight: 600;
  &:hover, &:focus {
    opacity: 0.6;
  }
}
.cookieConsent__Button.secondary {
  background: #666666;
  margin: 0px 12px 0px 0px;
}
.cookieConsent {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.cookieConsent__Right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  margin-top: 10px;
}
.backdrop-cookies {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #000;
  opacity: 0.5;
}
