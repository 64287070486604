@import "../../scss/variables";
.statistics-module {
  width: 100vw;
  position: absolute;
  bottom: 0px;
  z-index: 90;
  background-color: #fff;
  padding: 0px;
  .tab-content {
    height: 100%;
    padding: 0px;
    .tab-pane {
      height: 100%;
      .tab-container {
        height: 100%;
        display: flex;
        flex-direction: column;
        .subheader {
          height: $indicatorsBarHeight * 0.5;
          // box-shadow: inset 0px 0px 0px 4px #C00;
          // line-height: $indicatorsBarHeight * 0.5;
          font-size: 1.2rem;
          p {
            margin: 0px;
            line-height: $indicatorsBarHeight * 0.5;
          }
          h4 {
            margin: 0px;
            line-height: $indicatorsBarHeight * 0.5;
            text-align: center;
          }
          .form-check {
            padding-top: 8px;
            margin-bottom: 0px;
            font-size: 1.3rem;
            .form-check-input {
              margin-top: 3px;
            }
          }
        }
        .header {
          height: $indicatorsBarHeight;
          > div {
            display: flex;
            flex-direction: column;
            justify-content: center;
            h4 {
              margin: 0px;
              // line-height: $indicatorsBarHeight;
              text-align: center;
              // white-space: nowrap;
              // overflow: hidden;
              // text-overflow: ellipsis;
              span {
                font-size: 1.5rem;
                font-weight: normal;
                margin-top: -40px;
              }
              // overflow: hidden;
              // text-overflow: ellipsis;
            }
          }
        }
        .body {
          flex: 1;
          .col {
            // box-shadow: inset 0px 0px 0px 4px #C00;
            max-height: $statisticModuleChartHeight;
            overflow: hidden;
            overflow-y: auto;
          }
          &.w-subheader {
            .col {
              max-height: $statisticModuleChartHeight -
                ($indicatorsBarHeight * 0.5);
            }
          }
        }
        &.tab-container-projects {
          height: $statisticModulePanelHeight;
          padding: 0px;
          overflow-y: auto;

        }
      }
    }
  }
}

.statistic-module-trigger {
  position: absolute;
  width: $spacer * 2;
  height: $spacer * 2;
  text-align: center;
  left: 50%;
  margin-left: -($spacer);
  margin-top: -($spacer);
  font-size: 3rem;
  color: $red;
  background-color: #fff;
  border-radius: 50%;
  &:hover {
    cursor: pointer;
  }
  svg {
    position: absolute;
    top: 0px;
    left: 0px;
  }
}
