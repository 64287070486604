@import "../scss/variables";
.btn {
  font-size: 1.4rem;
  line-height: 2.8rem;
  border-radius: 4px;
  font-weight: bold;
  padding: 0px $spacer;
  &-link {
    color: $red;
    fill: $red;
    &:hover,
    &:active,
    &:first-child:active,
    &:not(.btn-check)+.btn:active {
      color: darken($red, 5%);
    }
  }
  &-primary {
    border-color: $red;
    background-color: $red;
    color: #FFF;
    &:hover,
    &:active,
    &:focus {
      background-color: darken($red, 5%) !important;
      border-color: darken($red, 5%) !important;
    }
    &:focus {
      box-shadow: none;
    }
    &:disabled {
      border-color: $red;
      background-color: $red;
      opacity: 0.5;
    }
    &.active,
    &:first-child:active // hard styles applied to the button
    {
      border-color: darken($red, 5%);
      background-color: darken($red, 5%);
    }
    
  }
  &-icon {
    // border: 1px solid #FFF;
    padding: 0px;
    line-height: 0px;
    opacity: 0.8;
    &:hover {
      opacity: 1;
    }
    svg {
      display: inline-block;
      width: $spacer;
      height: $spacer;
    }
  }
}

.bg {
  &-primary {
    background-color: $red !important;
  }
}

.link {
  color: $red;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}
