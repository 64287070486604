@import "../../scss/variables";

.filters-summary {
    box-shadow: inset 0px 1px 0px 0px $gray;
    background-color: #fff;
    h2, p {
        display: inline-block;
        margin: 0px;
    }
    h2 {
        font-size: 3.2rem;
        line-height: $indicatorsBarHeight;
    }
    p {
        // padding-left: $spacer;
        color: $grayDark;
    }
}