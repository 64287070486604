@import "../scss/variables";
.indicator {
    h5 {
        color: $dark;
        font-weight: normal;
        font-size: 1.8rem;
    }
}
.indicator-label {
    font-size: 1.4rem;
    color: $grayDark;
    padding-top: 12px;
    font-weight: normal;
}

ul.tags {
    padding: 0px;
    margin: 0px;
    list-style-type: none;
    // max-height: 70px;
    overflow: hidden;
    overflow-y: auto;
    li {
        display: inline-block;
    }
}

.d3-tooltip {
    position: absolute;
    pointer-events: none;
    background: #FFF;
    color: $dark;
    z-index: 999;
    font-size: 1.2rem;
    padding: $spacer*0.5 $spacer*0.8;
    border-radius: 4px;
    box-shadow: inset 0px 0px 0px 1px $gray;
}

.floating-container {
    position: absolute;
    left: 0px;
    top: $mainTabsHeight;
    padding: $spacer;
    z-index: 80;
    max-width: 300px;
    // Hide programs chart on small screens
    .programs-by-year-chart-container {
        display: none;
    }
}

.indicator-pill {
    display: inline-block;
    font-weight: normal;
    font-size: 1.3rem;
    border: 1px solid $grayDark;
    padding: 3px 4px;
    border-radius: 4px;
    margin-right: 4px;
}
