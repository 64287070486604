@import "../../scss/variables";
.table {
  &-ambits {
    // height: $statisticModulePanelHeight;
    thead {
      display: none;
      position: sticky;
      top: 0px;
      background-color: $light;
      border-bottom: 2px solid $grayDark;
      tr {
          th {
          position: relative;
          //   display: flex;
          padding: $spacer * 0.5 $spacer * 0.75;
          font-size: 1.2rem;
          font-weight: bold;
          white-space: nowrap;
          h5 {
            // title
            display: inline-block;
            margin: 0px;
            // line-height: 24px;
            margin-bottom: 0px;
            padding-bottom: 0px;
          }
          .header-control-search {
            display: inline-block;
            margin-left: $spacer;
          }
          .header-control-sort {
            position: absolute;
            right: 0px;
            top: 50%;
            margin-top: -9px;
          }
          
        }
      }
    }
    tbody {
      tr {
        // background-color: #fff;
        td {
          padding: $spacer * 0.1 0;
          font-size: 1.1rem;
          // box-shadow: inset 0px -1px 0px 0px $gray;
          // vertical-align: initial;
          line-height: $spacer;
          p {
            line-height: $spacer;
            margin: 0px;
            text-align: right;
            padding-right: $spacer * 0.5;
            font-size: 1.4rem;
          }
        }
        &:hover {
          // background-color: rgba(255,255,255,0.5);
          p {
            // font-weight: bold;
            color: $red;
          }
          cursor: pointer;
        }
      }
    }
  }
}

.wordcloud-container {
  height: 100%;
}
